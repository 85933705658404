export default [
  // TODO: disabled for now, should be moved into player profile
  // {
  //   // TODO: check why using invalid context logouts
  //   path: '/videos/:tagId(\\d+)?/:context(batting|pitching)?',
  //   name: 'videos',
  //   meta: { requiresAuth: true },
  //   components: {
  //     header: () => import('@/components/core/CoreHeader.vue'),
  //     default: () => import(/* webpackChunkName: "videoLibrary" */ '@/modules/video-library/components/VideoLibraryView.vue'),
  //   },
  //   props: {
  //     default: (route) => {
  //       const { tagId, context } = route.params;
  //       return {
  //         tagId: Number(tagId) || undefined,
  //         context
  //       };
  //     },
  //   }
  // },
];
